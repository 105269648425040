import React, { useEffect, useState } from 'react'
import { removePermission, updatePermissions, updateUsers } from '../Modules/Data'
import IUser from '../../server/src/Interfaces/IUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast'

const AddressPermissions: React.FC<{ addressId: string, sessionToken: string | undefined, updateTrigger: number }> = ({ addressId, sessionToken, updateTrigger }) => {

    const [permissions, setPermissions] = React.useState<any[]>([])
    const [users, setUsers] = React.useState<IUser[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        Promise.all([
            updateUsers(sessionToken, setUsers),
            updatePermissions(sessionToken, addressId, setPermissions)
        ]).finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTrigger])

    if (isLoading) {
        return (
            <div className="flex justify-center items-center">
                <FontAwesomeIcon icon={faSpinner} spin className="text-blue-500 text-2xl" />
            </div>
        )
    }

    return (
        <div className="mb-3 text-sm">
            {permissions.length === 0 && (
                <div className="flex justify-left">
                    <p className="text-gray-500 text-md"><FontAwesomeIcon icon={faCancel} className="text-red-500" /></p>
                </div>
            )}
            <table className="w-full border-collapse">
                <tbody>
                    {permissions.map((permission: any) => {
                        async function handleRemovePermission(permissionId: any): Promise<void> {
                            const removed = await removePermission(sessionToken, permissionId)
                            if (removed) {
                                toast.success('Valittu oikeus kumottu.')
                                updatePermissions(sessionToken, addressId, setPermissions)
                            } else {
                                toast.error('Virhe kumoamisessa.')
                            }
                        }

                        const user = users.find((user: IUser) => user._id === permission.userId)
                        return (
                            <tr key={permission.permissionId} className="border-b border-gray-200 hover:bg-gray-50 transition-colors duration-150 ease-in-out">
                                <td className="p-2 w-6">
                                    <svg className="w-4 h-4 text-blue-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                    </svg>
                                </td>
                                <td className="p-2 text-xs text-gray-700 text-left">
                                    {user?.firstName} {user?.lastName}
                                    {permission.expireAt && ` - Expires: ${new Date(permission.expireAt).toLocaleString('fi-FI', { timeZone: 'Europe/Helsinki' })}`}
                                </td>
                                <td className="p-2 w-6">
                                    <button className="text-neutral-500 hover:text-neutral-700" onClick={() => handleRemovePermission(permission.permissionId)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default AddressPermissions