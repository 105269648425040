import React from 'react'
import { GlobalContext } from '../App'
import GlobalState from '../Interfaces/IGlobalState'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { MenuItems } from './MenuItem'

const MenuBar: React.FC = () => {
    const { globalVariable, setGlobalVariable } = React.useContext(GlobalContext)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false)

    const updateMenuSelection = (selection: string) => {
        setGlobalVariable((prev: GlobalState) => ({
            ...prev,
            menuSelection: selection
        }));
        setIsMobileMenuOpen(false);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-lg font-roboto z-50">
            <nav className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row md:items-center">
                    <div className="md:hidden self-start p-4">
                        <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
                            <FontAwesomeIcon icon={faBars} className="text-2xl" />
                        </button>
                    </div>
                    <ul className={`md:flex md:space-x-6 ${isMobileMenuOpen ? 'block' : 'hidden'} md:block w-full md:w-auto my-2 md:flex-wrap`}>
                        {MenuItems.map((item: any, index: number) => {
                            // Check if globalVariable.permissions contains at least one needed permission from item.permissions
                            if (item.hidden || (globalVariable.sessionValid && item.permissions && item.permissions.length > 0 && !item.permissions.some((permission: any) => globalVariable.permissions?.includes(permission)))) {
                                return null
                            } else {
                                return (
                                    <li key={index} className="text-left">
                                        <div onClick={() => updateMenuSelection(item.id)} className="flex items-center py-2 px-4 hover:bg-white hover:bg-opacity-20 rounded-full transition duration-200 cursor-pointer mx-2">
                                            <FontAwesomeIcon icon={item.icon} className="mr-2" />
                                            <span className="font-semibold">{item.title}</span>
                                        </div>
                                    </li>
                                )
                            }
                        })}
                        <li key="username" className="text-left">
                            <div onClick={() => { localStorage.clear(); window.document.location.reload(); }} className="flex items-center py-2 px-4 hover:bg-white hover:bg-opacity-20 rounded-full transition duration-200 cursor-pointer mx-2">
                                <FontAwesomeIcon icon={faSignOut} className="mr-2" />
                                <span className="font-semibold">Kirjaudu Ulos</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default MenuBar
