import React, { FormEvent, useEffect } from 'react'
import { API_URL } from '../Globals'
import ky from 'ky'
import { ITask } from '../../server/src/Interfaces/ITask'
import { GlobalContext } from '../App'
import IUser from '../../server/src/Interfaces/IUser'
import { updateUsers } from '../Modules/Data'

const AddressTasks: React.FC<{ addressId: string }> = (addressId) => {
  const { globalVariable } = React.useContext(GlobalContext)
  const [tasks, setTasks] = React.useState<ITask[]>([])
  const [users, setUsers] = React.useState<IUser[]>([])
  const [taskForm, setTaskForm] = React.useState<ITask>({
    task: '',
    addressId: addressId.addressId,
    createdAt: new Date()
  })

  const getTasks = async () => {
    console.log("Getting tasks...")
    const tasks = await ky.get(`${API_URL}/api/task/address/${addressId.addressId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + globalVariable.sessionToken
      }
    })
    if (tasks.status === 200) {
      tasks.json().then((data) => {
        setTasks(data as ITask[])
      })
    }
  }

  const createTask = async (task: ITask) => {
    const tasks = await ky.post(`${API_URL}/api/task/create`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + globalVariable.sessionToken
      },
      json: task
    })
    if (tasks.status === 201) {
      getTasks()
      setTaskForm({
        task: '',
        addressId: addressId.addressId,
        createdAt: new Date()
      })
    }
  }

  useEffect(() => {
    updateUsers(globalVariable.sessionToken, setUsers)
    getTasks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    console.log("Not implemented yet.")
  }

  return (
    <div className="mt-6">
      <h2 className="text-xl text-blue-800 mb-4">Työtehtävät</h2>
      <ul className="space-y-4 mb-4">
        {tasks.map((task, index) => (
          <li key={index} className="bg-white p-4 rounded-md shadow-sm border border-gray-200 relative">
            <div className="absolute top-0 left-0 bg-blue-500 text-white px-2 py-1 text-xs rounded-tl-md rounded-br-md">
              {`${users.find((u: IUser) => u._id?.toString() === task.creatorId?.toString())?.firstName || 'Unknown'} ${users.find((u: IUser) => u._id?.toString() === task.creatorId?.toString())?.lastName || 'Unknown'}`}
            </div>
            <div className="absolute top-0 right-0 bg-green-500 text-white px-2 py-1 text-xs rounded-tr-md rounded-bl-md">
              {new Date(task.createdAt).toLocaleString('fi-FI', { timeZone: 'Europe/Helsinki' })}
            </div>
            <p className="mt-6">{task.task}</p>
          </li>
        ))}
      </ul>
      <form onSubmit={handleSubmit} className="mt-4">
        <input
          type="text"
          placeholder="Suoritettu tehtävä"
          onChange={(event) => setTaskForm({...taskForm, task: event.target.value })}
          value={taskForm.task}
          className="w-full px-4 py-2 rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-150 ease-in-out mb-2"
        />
        <button 
          type="button"
          onClick={() => createTask(taskForm)}
          className="w-full sm:w-auto px-6 py-3 mt-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out"
        >
          Lisää Tehtävä
        </button>
      </form>
    </div>
  )
}

export default AddressTasks
