import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import ky from 'ky'
import { GlobalContext } from '../App'
import { API_URL } from '../Globals'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface AllCustomerChartProps {
  isOpen: boolean;
  onClose: () => void;
  year: number;
}

interface SweepData {
  month: string;
  sweeps: number;
}

const AllCustomerChartModal: React.FC<AllCustomerChartProps> = ({ isOpen, onClose, year }) => {
  const { globalVariable } = useContext(GlobalContext)
  const [chartData, setChartData] = useState<SweepData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ky.get(`${API_URL}/api/address/statistic/totalSweeps?year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${globalVariable.sessionToken}`,
            'Token': localStorage.getItem('token') as string
          }
        }).json<SweepData[]>();
        setChartData(response);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };
    fetchData();
  }, [globalVariable.sessionToken]);

  const data = {
    labels: chartData.map((item: any) => item.month),
    datasets: [
      {
        label: 'Määrä',
        data: chartData.map((item: { sweeps: number }) => item.sweeps),
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: `"Seuraava Nuohous" ${year}`,
      },
    },
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out animate-fade-in">
      <div className="bg-white rounded-lg shadow-lg p-4 sm:p-8 w-full sm:w-11/12 max-w-6xl mx-auto transform transition-transform duration-300 ease-in-out scale-100 animate-zoom-in relative">
        <button className="absolute top-2 sm:top-4 right-4 sm:right-6 text-gray-500 hover:text-gray-700 z-10" onClick={onClose}>
          <FontAwesomeIcon icon={faClose} />
        </button>
        <div className="bg-gradient-to-r from-blue-100 to-blue-300 border-l-4 border-blue-500 text-blue-800 px-2 sm:px-4 py-2 sm:py-3 shadow-md rounded-lg relative mb-4 mt-10" role="alert">
          <strong className="font-bold text-xs">HUOMIO! </strong>
          <span className="block sm:inline text-xs font-roboto">Tämän tilaston tarkoitus ei ole antaa historiallista dataa. Tämä graafi on dynaaminen ja se laskee juuri tällä hetkellä kannassa olevat "seuraavat nuohoukset" kuukausittain vuodelle {year}</span>
        </div>
        <div style={{ width: '100%', height: '60vh', margin: 'auto' }}>
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default AllCustomerChartModal;