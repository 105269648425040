import React, { useEffect, useState, useCallback } from 'react'
import { updateUsers } from '../Modules/Data'
import IAddress from '../../server/src/Interfaces/IAddress'
import IUser from '../../server/src/Interfaces/IUser'
import ky from 'ky'
import toast from 'react-hot-toast'
import { API_URL } from '../Globals'

interface AssignToWorkerProps {
  sessionToken: string | undefined
  address: IAddress | undefined
  cancelFn?: () => void
  onSuccess?: () => void
}

const AssignToWorker: React.FC<AssignToWorkerProps> = ({ sessionToken, address, cancelFn, onSuccess }) => {
  const [users, setUsers] = useState<IUser[]>([])
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined)
  const [duration, setDuration] = useState<number | undefined>(undefined)
  const [filled, setFilled] = useState(false)

  const durations = [
    {
      value: 1,
      text: '1',
      expiring: new Date(Date.now() + 24 * 60 * 60 * 1000).toLocaleString('fi-FI', { dateStyle: 'short', timeStyle: 'short', timeZone: 'Europe/Helsinki' })
    },
    {
      value: 2,
      text: '7',
      expiring: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleString('fi-FI', { dateStyle: 'short', timeStyle: 'short', timeZone: 'Europe/Helsinki' })
    },
    {
      value: 3,
      text: '14',
      expiring: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toLocaleString('fi-FI', { dateStyle: 'short', timeStyle: 'short', timeZone: 'Europe/Helsinki' })
    },
    {
      value: 4,
      text: '30',
      expiring: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleString('fi-FI', { dateStyle: 'short', timeStyle: 'short', timeZone: 'Europe/Helsinki' })
    }
  ]

  useEffect(() => {
    const fetchUsers = async () => {
      await updateUsers(sessionToken, setUsers)
    }
    fetchUsers()
  }, [sessionToken])

  const checkFilled = useCallback(() => {
    setFilled(!!selectedUser?._id && duration !== undefined)
  }, [selectedUser, duration])

  useEffect(() => {
    checkFilled()
  }, [selectedUser, duration, checkFilled])

  const assignUser = async () => {
    try {
      const response = await ky.post(`${API_URL}/api/address/permission/create`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionToken}`,
          'Token': localStorage.getItem('token') as string
        },
        json: {
          addressId: address?._id,
          userId: selectedUser?._id,
          expireAt: duration
        }
      })

      if (response.ok) {
        toast.success('Oikeudet myönnetty.', { duration: 10000 })
        onSuccess?.()
        cancelFn?.()
      }
    } catch (error) {
      toast.error('Virhe! Oikeuksia ei pystytty myöntämään.', { duration: 10000 })
    }
  }

  const handleUserChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const userId = e.target.value
    setSelectedUser(users.find(user => user._id.toString() === userId))
  }

  const handleDurationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setDuration(value === "-1" ? undefined : parseInt(value))
  }

  const selectedDuration = durations.find(d => d.value === duration)

  return (
    <div className="container mx-auto mb-10">
      <div className="p-6 sm:p-10 bg-gradient-to-r from-blue-100 to-indigo-100 border border-blue-300 rounded-xl shadow-2xl">
        <h2 className="text-2xl font-semibold text-blue-900 mb-8">Myönnä oikeudet työntekijälle</h2>
        <form className="space-y-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div>
              <label htmlFor="user-select" className="block text-sm font-medium text-gray-700 mb-2">Valitse työntekijä</label>
              <select
                id="user-select"
                onChange={handleUserChange}
                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
              >
                <option value="-1">Toteutus</option>
                {users.map((user) => (
                  <option key={user._id.toString()} value={user._id.toString()}>{user.firstName} {user.lastName}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="duration-select" className="block text-sm font-medium text-gray-700 mb-2">Valitse kesto</label>
              <select
                id="duration-select"
                onChange={handleDurationChange}
                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
              >
                <option value="-1">Aika</option>
                <option value="1">1 päiväksi</option>
                <option value="2">7 päiväksi</option>
                <option value="3">14 päiväksi</option>
                <option value="4">30 päiväksi</option>
              </select>
            </div>
          </div>
          {selectedUser && duration && (
            <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative" role="alert">
              <span className="block text-xs">
                Olet myöntämässä oikeutta henkilölle {selectedUser.firstName} {selectedUser.lastName} muokata kohdetta {address?.address}, {address?.zip} {address?.city} seuraavan {selectedDuration?.text} päivän aikana. Tämä sisältää perustiedon näkemisen, muokkaamisen sekä tehtävien lisäämisen kyseisen kohteen tietoihin. Näillä näkymin oikeudet päättyvät noin {selectedDuration?.expiring}
              </span>
            </div>
          )}
          <div className="mt-10 flex flex-col sm:flex-row justify-end space-y-4 sm:space-y-0 sm:space-x-4">
            <button
              type="button"
              disabled={!filled}
              onClick={assignUser}
              className={`w-full sm:w-auto px-6 py-3 ${filled ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-400"} text-white font-medium rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200 ease-in-out`}
            >
              Tallenna
            </button>
            <button
              type="button"
              onClick={cancelFn}
              className="w-full sm:w-auto px-6 py-3 bg-red-600 text-white font-medium rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition duration-200 ease-in-out"
            >
              Peruuta
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AssignToWorker