import React, { Dispatch, useState } from 'react'
import './Login.css'
import ky from 'ky'
import toast from 'react-hot-toast'
import GlobalState from '../Interfaces/IGlobalState'
import IUser from '../../server/src/Interfaces/IUser'
import { API_URL } from '../Globals'

const Login: React.FC<{ globalVariable: Dispatch<React.SetStateAction<GlobalState>> }> = ({ globalVariable }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    ky.post(API_URL + '/api/login', {
      json: {
        username: username,
        password: password
      }
    }).then(async response => {
      if (response.status === 200) {
        const data: IUser = await response.json()

        globalVariable({
          menuSelection: 'home',
          sessionToken: data.sessionToken,
          sessionValid: true,
          permissions: data.permissions,
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
        })

        localStorage.setItem('sessionToken', data.sessionToken || '')
        localStorage.setItem('username', data.username || '')
        localStorage.setItem('firstName', data.firstName || '')
        localStorage.setItem('lastName', data.lastName || '')
        localStorage.setItem('sessionValid', 'true')
        localStorage.setItem('permissions', JSON.stringify(data.permissions) || '')
        localStorage.setItem('token', data.token || '')

        toast.success(`Kirjautuminen onnistui!`)
      }
    }).catch(error => {
      toast.error('Väärä käyttäjänimi tai salasana.')
    })
  }

  return (
    <div className="min-h-screen flex items-start justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-64 w-auto"
            src="hexacat.svg"
            alt="Hexacat"
          />
          <h2 className="mt-6 text-center text-5xl font-extrabold text-gray-900 hexacat">
            Hexacat
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Käyttäjätunnus
              </label>
              <input
                id="username"
                name="text"
                type="text"
                autoComplete="current-username"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Käyttäjätunnus"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Salasana
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Salasana"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="button"
              onClick={handleSubmit}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Tunnistaudu
            </button>
          </div>
        </form>
        <div className="mt-4 text-center text-xs text-gray-400">
          <p className="mb-2">Project Hexagon Cat</p>
          <p>Build: AE3F03E</p>
        </div>
      </div>
    </div>
  )
}

export default Login
