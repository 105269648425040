import React, { createContext, useEffect, useState } from 'react';
import './App.css';
import MenuBar from './Menu/MenuBar';
import GlobalState from './Interfaces/IGlobalState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { MenuItems } from './Menu/MenuItem';
import { Toaster } from 'react-hot-toast';
import Login from './Login/Login';
import { renewToken } from './Modules/Data';

export const GlobalContext = createContext<{
  globalVariable: GlobalState;
  setGlobalVariable: React.Dispatch<React.SetStateAction<GlobalState>>;
}>({} as any);

const parsePermissions = (permissionsString: string | null): string[] => {
  try {
    return permissionsString ? JSON.parse(permissionsString) : []
  } catch (error) {
    console.error('Error parsing permissions from localStorage:', error)
    return []
  }
}

function App() {
  const [globalVariable, setGlobalVariable] = useState<GlobalState>({
    menuSelection: 'home',
    sessionToken: localStorage.getItem('sessionToken') || undefined,
    sessionValid: localStorage.getItem('sessionValid') ? true : false,
    permissions: parsePermissions(localStorage.getItem('permissions') || ''),
    username: localStorage.getItem('username') || '',
    firstName: localStorage.getItem('firstName') || '',
    lastName: localStorage.getItem('lastName') || ''
  });

  useEffect(() => {
    // Renew attempt if token is already set
    if (localStorage.getItem('token')) {
      renewToken();
    }
  }, []);

  return (
    <GlobalContext.Provider value={{ globalVariable, setGlobalVariable }}>
      <Toaster />
      {globalVariable.sessionValid && (
        <div className="App">
          <MenuBar />
          {globalVariable.menuSelection !== 'home' && (
            <div style={{ marginTop: '60px', padding: '10px' }}>
              <div className="container mx-auto sm:px-0">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="inline-flex items-center space-x-1 md:space-x-3 mb-1">
                    <li className="inline-flex items-center">
                      <div className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                        Sivu
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faAngleRight} className='text-gray-400 mt-1' />
                        <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">{(MenuItems.find((mi) => mi.id === globalVariable.menuSelection))?.title}</span>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          )}
          {(() => {
            const selectedMenuItem = MenuItems.find((mi) => mi.id === globalVariable.menuSelection)
            if (selectedMenuItem && selectedMenuItem.component) {
              const Component = selectedMenuItem.component
              return <Component />
            }
            return null
          })()}
        </div>
      )}
      {!globalVariable.sessionValid && (
        <Login globalVariable={setGlobalVariable} />
      )}
    </GlobalContext.Provider>
  );
}

export default App;