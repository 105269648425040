import { IMenuItem } from "../Interfaces/IMenuItem";
import { faDashboard, faHouseChimney, faMarker, faPlus } from "@fortawesome/free-solid-svg-icons";
import Customers from "../Customers/Customers";
import AddCustomer from "../Customers/AddCustomer";
import Landing from "../Landing/Landing";

export const MenuItems: IMenuItem[] = [
  {
    title: 'Etusivu',
    description: 'Etusivu',
    id: 'home',
    icon: faDashboard,
    permissions: ['USER', 'ADMINISTRATOR'],
    component: Landing,
    hidden: false
  },
  {
    title: 'Kohteet',
    description: 'Kohdehallinta',
    id: 'customers',
    icon: faHouseChimney,
    component: Customers,
    permissions: ['ADMINISTRATOR'],
    hidden: false
  },
  {
    title: 'Uusi Kohde',
    description: 'Uusi Kohde',
    id: 'add-customer',
    icon: faPlus,
    permissions: ['ADMINISTRATOR'],
    component: AddCustomer,
    hidden: false
  },
  {
    title: 'Työkohteet',
    description: 'Työkohteet',
    id: 'assigned-tasks',
    icon: faMarker,
    permissions: ['USER'],
    component: Customers,
    hidden: false
  }
]